.meeting-section {
  position: relative;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  /* height: 80vh; */
  /* background: red; */
}

.meeting-section-content {
  padding: 0px 3em;
}

.meeting-section-content-centre {
  display: table-cell;
  vertical-align: middle;
}

.meeting-section-img {
  /* padding: 2em; */
  border: none;
  /* box-shadow: 0 4px 11px 0 #e0e0e038, 0 4px 15px 0 #e0e0e036; */
  min-height: 300px;
}

.meeting-section-form {
  /* padding: 2em; */
  border: 1px solid #ffffff !important;
  /* box-shadow: 0 4px 11px 0 #e0e0e038, 0 4px 15px 0 #e0e0e036; */
  min-height: 300px;
}

.meeting-form {
  padding: 0em 2em;
}

.meeting-form p {
  font-size: 0.9em;
  margin-bottom: 0.5em;
  letter-spacing: 0.7px;
}
