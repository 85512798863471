.home-banner-content-1 {
  /* height: 20em; */
  display: table-cell;
  vertical-align: middle;
  padding: 3em 3em;
}

.home-banner-content-1 h2 {
  font-weight: 400;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.home-banner-content-1 h5 {
  line-height: 1.5em;
  color: var(--tertiary-color);
}

.hom-img {
  border-radius: 0.5rem;
  max-width: 50rem;
  width: 100%;
}
