.row-padding-video {
    padding: 0 25em;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
  }
.scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
  }
  
.scrolling-wrapper-flexbox .card {
    padding: 15px 1px;
    width: 33.33%;
    box-shadow: none;
  }
  
.scrolling-wrapper-flexbox {
    width: 100%;
    -webkit-overflow-scrolling:touch;
}

/* .scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
} */
  
.scrolling-wrapper-flexbox::-webkit-scrollbar {
    width: 0.5em;
    height: 0.4em;
}
   
.scrolling-wrapper-flexbox::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrolling-wrapper-flexbox::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.meeting-video-half {
    height: 7em;
    object-fit: cover;
}

.meeting-video-full {
    width: 700px;
    height: 26em;
    position: relative;
    object-fit: cover;
}

.full-width-video .hover-effect:hover .chat-icons-display {
    opacity: 1!important;
}

.full-width-video {
    text-align: center;
    margin-top: 2em;
}

.space-mg {
    height: 1.5em;
}
.chat-icons {
    display: flex;
    margin-bottom: 0;
}

.chat-icons-display li {
    list-style: none;
    /* font-size: 26px; */
    /* border: solid rgb(230, 230, 255) 2px; */
    border-radius: 100%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: rgb(230, 230, 255);
    margin-right: 1em;

}
.chat-icons-display li:hover {
   background-color: rgb(255, 255, 255);
}

.meeting-font {
    max-width: 1.8em;
}

.chat-icons-display {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    position: absolute;
    bottom: 10px;
    left: 470px;
    padding: 20px;
    opacity: 0;
    transition: all 1s ease-out;
}

.chat-icons-sec {
    border: solid rgb(230, 230, 255) 2px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: rgb(230, 230, 255);
}

.chat-icons-sec .chat-icon {
    max-width: 1.8em;
}

.half-width-content-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

/* .half-width-content-col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
    padding-right: 15px;
    padding-left: 15px;
} */
/* 
/* .half-width-content-col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    padding-right: 15px;
    padding-left: 15px;
} */ 



.video-chat-box .chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.video-chat-box .chat li
{
    margin-bottom: 40px;
    padding-bottom: 5px;
    margin-top: 10px;
    width: 80%;
}

.video-chat-box .chat li .chat-body p
{
    margin: 0;
}
.video-chat-box .chat-care
{
    overflow-y: scroll;
    height: 350px;
}
.video-chat-box .chat-care .chat-img
{
    width: 50px;
    height: 50px;
}
.video-chat-box .chat-care .img-circle
{
    border-radius: 50%;
    max-width: 3em;
}
.video-chat-box .chat-care .chat-img
{
    display: inline-block;
}
.video-chat-box .chat-care .chat-body
{
    display: inline-block;
    max-width: 80%;
    background-color: #eeeeee;
    border-radius: 12.5px;
    padding: 15px;
}

.video-chat-box .chat-care .chat-body-1
{
    display: inline-block;
    max-width: 80%;
    background-color: #b1b1b2;
    border-radius: 12.5px;
    padding: 15px;
}

.video-chat-box .chat-care .chat-body strong
{
  color: #0169DA;
}

.video-chat-box .chat-care .admin
{
    text-align: right ;
    float: right;
}
.video-chat-box .chat-care .admin p
{
    text-align: left ;
}
.video-chat-box .chat-care .agent
{
    text-align: left ;
    float: left;
}
.video-chat-box .chat-care .left
{
    float: left;
}
.video-chat-box .chat-care .right
{
    float: right;
}

.video-chat-box .clearfix {
  clear: both;
}

.video-chat-box .card-footer .input-group {
    display: flex;
    align-items: center;
}

.video-chat-box .card-footer .input-group  .form-control{
    padding: 1.4em;
}
