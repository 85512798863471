.margin-btm {
  margin-bottom: 20px;
}

.subscription-desc h5 {
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.card-header {
  background-color: #ffffff !important;
}
.card-title {
  line-height: 1.7em;
  letter-spacing: 0.3px;
}
