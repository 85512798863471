.settings-section {
  padding-left: 5%;
  padding-right: 5%;
}
.settings-card {
  width: 25%;
  /* max-height: 100px; */
  /* min-height: 100px; */
  /* display: inline-block; */
  padding: 2em;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.settings-round {
  height: 8em;
  width: 8em;
  background-color: inherit;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-left: 1.5em; */
}
.settings-round i {
  font-size: 3em;
  color: var(--secondary-color);
}
.settings-text {
  /* margin: 10px; */
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 500;
  color:var(--primary-color);
}

.settings-vertical-code {
  border-right: 1px solid #343a4038;
}

.c-1 {
  background-color: #55e6c1;
}

.c-2 {
  background-color: #bdc581;
}
.c-3 {
  background-color: #1b9cfc;
}
.c-4 {
  background-color: #2c3a47;
}

.c-5 {
  background-color: #eab543;
}

.c-6 {
  background-color: #3b3b98;
}
.c-7 {
  background-color: #b33771;
}
